<template>
  <div
    class="index-banner"
    :style="{'background-image':'url(assets/icon/banner-bg.png)','background-size': '46% 100%','background-repeat': 'no-repeat', 'background-position': 'right 0px'}"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-8 mb-5">
          <div class="banner-form section-title py-4">
            <h4 class="text-center title text-primary mb-0">
              FREE TRIAL
              <p class="mb-0">Please Fill below form for Demo request</p>
            </h4>
            <form @submit.prevent="submit" class="row p-3">
              <div class="col-md-6">
                <!-- <div class="row mb-2">
                  <div class="col-md-5 mr-0 pr-0">
                    <label for="name">Full Name</label>
                  </div>
                  <div class="col-md-7" :class="{ invalid: $v.formData.name.$error }">
                    <input
                      type="text"
                      class="form-control border-radius-0"
                      placeholder="Your Name"
                      id="name"
                      @blur="$v.formData.name.$touch()"
                      v-model.trim="formData.name"
                      required
                    />
                  </div>
                  <div class="col-md-12 text-right" v-if="$v.formData.name.$error">
                    <p class="font-xs text-danger mb-1" v-if="!$v.formData.name.required">Name field is required.</p>
                    <p
                      class="error_level"
                      v-if="!$v.formData.name.minLength"
                    >At least 2 character name.</p>
                  </div>
                </div> -->
                <div class="row mb-2">
                  <div class="col-md-5 mr-0 pr-0">
                    <label for="company_name" class="page-text mt-1">Company Name</label>
                  </div>
                  <div class="col-md-7" :class="{ invalid: $v.formData.company.$error }">
                    <input
                      type="text"
                      class="form-control border-radius-0"
                      placeholder="Company Name"
                      id="company_name"
                      @blur="$v.formData.company.$touch()"
                      v-model.trim="formData.company"
                      required
                    />
                  </div>
                  <div class="col-md-12 text-right" v-if="$v.formData.company.$error">
                    <p
                      class="font-xs text-danger mb-1"
                      v-if="!$v.formData.company.required"
                    >Company Name field is required.</p>
                    <p
                      class="font-xs text-danger mb-1"
                      v-if="!$v.formData.company.minLength"
                    >At least 2 character company name.</p>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-5 mr-0 pr-0">
                    <label for="company_address" class="page-text mt-1">Company Address</label>
                  </div>
                  <div class="col-md-7" :class="{ invalid: $v.formData.address.$error }">
                    <input
                      type="text"
                      class="form-control border-radius-0"
                      placeholder="Company Address"
                      id="company_address"
                      @blur="$v.formData.address.$touch()"
                      v-model.trim="formData.address"
                      required
                    />
                  </div>
                  <div class="col-md-12 text-right" v-if="$v.formData.address.$error">
                    <p
                      class="font-xs text-danger mb-1"
                      v-if="!$v.formData.address.required"
                    >Address field is required.</p>
                    <p
                      class="error_level"
                      v-if="!$v.formData.address.minLength"
                    >At least 2 character address.</p>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-5 mr-0 pr-0">
                    <label for="email" class="page-text mt-1">Email</label>
                  </div>
                  <div class="col-md-7" :class="{ invalid: $v.formData.email.$error }">
                    <input
                      type="text"
                      class="form-control border-radius-0"
                      placeholder="Company Email"
                      id="company_email"
                      @blur="$v.formData.email.$touch()"
                      v-model.trim="formData.email"
                      required
                    />
                  </div>
                  <div class="col-md-12 text-right" v-if="$v.formData.email.$error">
                    <p
                      class="font-xs text-danger mb-1"
                      v-if="!$v.formData.email.required"
                    >Email field is required.</p>
                    <p
                      class="font-xs text-danger mb-1"
                      v-if="!$v.formData.email.minLength"
                    >At least 6 character email.</p>
                    <p class="font-xs text-danger mb-1" v-if="!$v.formData.email.email">Please enter valid email.</p>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-5 mr-0 pr-0">
                    <label for="pan" class="page-text mt-1">PAN/VAT No.</label>
                  </div>
                  <div class="col-md-7" :class="{ invalid: $v.formData.pan.$error }">
                    <input
                      type="number"
                      class="form-control border-radius-0"
                      placeholder="PAN / Registration Number"
                      id="pan"
                      @blur="$v.formData.pan.$touch()"
                      v-model.trim="formData.pan"
                      required
                    />
                  </div>
                  <div class="col-md-12 text-right" v-if="$v.formData.pan.$error">
                    <p
                      class="font-xs text-danger mb-1"
                      v-if="!$v.formData.pan.required"
                    >PAN/Registration field is required.</p>
                    <p
                      class="font-xs text-danger mb-1"
                      v-if="!$v.formData.pan.minLength"
                    >9 character PAN/Registration.</p>
                    <p
                      class="font-xs text-danger mb-1"
                      v-if="!$v.formData.pan.maxLength"
                    >9 character PAN/Registration.</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row mb-2">
                  <div class="col-md-5 mr-0 pr-0">
                    <label for="contact_number" class="page-text mt-1">Contact Number</label>
                  </div>
                  <div class="col-md-7" :class="{ invalid: $v.formData.contact.$error }">
                    <input
                      type="text"
                      class="form-control border-radius-0"
                      placeholder="Contact Number"
                      id="contact_number"
                      @blur="$v.formData.contact.$touch()"
                      v-model.trim="formData.contact"
                      required
                    />
                  </div>
                  <div class="col-md-12 text-right" v-if="$v.formData.contact.$error">
                    <p
                      class="font-xs text-danger mb-1"
                      v-if="!$v.formData.contact.required"
                    >Contact field is required.</p>
                    <p
                      class="font-xs text-danger mb-1"
                      v-if="!$v.formData.contact.minLength"
                    >At least 7 character contact number.</p>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-5 mr-0 pr-0">
                    <label for="plan" class="page-text mt-1">Product</label>
                  </div>
                  <div class="col-md-7">
                    <select
                      class="form-control border-radius-0"
                      id="product"
                      required
                    >
                      <option value>Select Product</option>
                      <option>Service Oriented</option>
                      <option value="">Product Oriented</option>
                      <option value="">Manufacturing</option>
                      <option value="">Resturant Management System</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-5 mr-0 pr-0">
                    <label for="plan" class="page-text mt-1">Plan Type</label>
                  </div>
                  <div class="col-md-7" :class="{ invalid: $v.formData.plan_id.$error }">
                    <select
                      class="form-control border-radius-0"
                      id="plan"
                      @blur="$v.formData.plan_id.$touch()"
                      v-model.trim="formData.plan_id"
                      required
                    >
                      <option value>Select Your Plan</option>
                      <option
                        v-for="(plan,key) in dataLists"
                        :value="plan.id"
                        :key="key"
                      >{{plan.title}}</option>
                    </select>
                    <div v-if="$v.formData.plan_id.$error">
                      <p
                        class="font-xs text-danger mb-1"
                        v-if="!$v.formData.plan_id.required"
                      >Please choose your plan.</p>
                      <p class="font-xs text-danger mb-1" v-if="!$v.formData.plan_id.numeric">Invalid plan id.</p>
                    </div>
                  </div>
                </div>
                <!-- <div class="row mb-2">
                  <div class="col-md-5 mr-0 pr-0">
                    <label for="plan">VAT / PAN</label>
                  </div>
                  <div class="col-md-7">
                    <select
                      class="form-control border-radius-0"
                      id="plan"
                      required
                    >
                      <option value>Select Option</option>
                      <option value="">VAT</option>
                      <option value="">PAN</option>
                    </select>
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-md-5 mr-0 pr-0">
                    <label for="workspace" class="page-text mt-1">Workspace</label>
                  </div>
                  <div class="col-md-7" :class="{ invalid: $v.formData.workspace.$error }">
                    <input
                      type="text"
                      class="form-control border-radius-0"
                      placeholder="Workspace"
                      id="workspace"
                      @blur="checkWorkspace"
                      @change="$v.formData.workspace.$touch()"
                      v-model.trim="formData.workspace"
                      required
                    />
                    <span class="input-inside-icon text-success">
                        <i class="fas fa-check"></i>
                    </span>
                  </div>
                  <div class="col-md-12 text-right" v-if="$v.formData.workspace.$error">
                    <p
                      class="font-xs text-danger mb-1"
                      v-if="!$v.formData.workspace.required"
                    >Workspace field is required.</p>
                    <p
                      class="font-xs text-danger mb-1"
                      v-if="!$v.formData.workspace.minLength"
                    >At least 4 character workspace.</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right pt-2">
                <button type="submit" class="btn btn-s btn-primary border-radius-0">Submit</button>
                <!-- <p
                  :class="eventMessage.indexOf('success')>=0?'success_level':'error_level'"
                >{{eventMessage}}</p> -->
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-3">
          <img src="assets/icon/mascot.png" class="img-fluid banner-image-fluid" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["processing", "eventMessage", "dataLists"]),
  },
  mounted() {
    this.$store.commit("getData", "api/plans");
  },
  data() {
    return {
      formData: {
        name: "",
        company: "",
        address: "",
        email: "",
        contact: "",
        pan: "",
        plan_id: "",
        workspace: "",
      },
    };
  },
  validations: {
    formData: {
      name: { required, minLength: minLength(2), maxLength: maxLength(255) },
      company: { required, minLength: minLength(2), maxLength: maxLength(255) },
      address: { required, minLength: minLength(2), maxLength: maxLength(255) },
      email: { required, email, minLength: minLength(6) },
      contact: { required, numeric, minLength: minLength(7) },
      pan: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(9),
      },
      plan_id: { required, numeric },
      workspace: { required, minLength: minLength(4) },
    },
  },
  methods: {
    checkWorkspace() {
      if (this.formData.workspace) {
        this.$store.commit(
          "getData",
          `api/workspace/${this.formData.workspace}`
        );
      }
    },
    submit() {
      if (!this.$v.formData.$invalid) {
        this.$store.commit("setApiUrl", "api/demo/store");
        this.$store.commit("addData", this.formData);
      } else {
        this.$store.commit("setEventMessage", `Please fill required fields.`);
      }
    },
  },
  watch: {
    "formData.workspace"(value) {
      if (value) {
        this.formData.workspace = value
          .toLowerCase()
          .trim()
          .split(".")
          .join("")
          .replace(/[\s\/]/g, "");
      } else {
        this.error = "";
      }
    },
    eventMessage(value) {
      if (value && value.indexOf("success") >= 0) {
        this.formData.name = "";
        this.formData.company = "";
        this.formData.address = "";
        this.formData.email = "";
        this.formData.contact = "";
        this.formData.pan = "";
        this.formData.plan_id = "";
        this.formData.workspace = "";
      }
    },
  },
};
</script>

<style scoped>
.banner-image-fluid {
  margin-left: -3rem;
}
</style>